
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';
import Languague from '../../languague/index.vue';
import TelInput from '@/common/web/telInput/index.vue';
import {Server, App} from '@/services';
interface LoginParamsType {
  username: '';
  password: '';
}
@Options({
  components: {
    Languague,
    TelInput
  },
  props: {
    onToggle: Function,
    linknumber: String
  },
  methods: {
    async submit() {
      const data = await Server.Web.Common.register({
        user_account: this.username,
        password: this.password,
        real_name: this.lastname + this.firstname || this.realname,
        phone_number: this.$refs.tel.info.phoneObject.number.e164,
        email: this.email,
        wechat: this.wechat,
        qq: this.qq,
        skype: this.skype,
        line: this.line,
        zalo: this.zalo,
        link_token: this.linknumber
      });
      // console.log('phone_number: ', this.$refs.tel.info.phoneObject.number.e164);
      // this.loading = false;
      if (data instanceof Error) {
        this.$message(data.message);
        return false;
      }
      this.password = '';
      this.username = '';
      this.realname = '';
      this.lastname = '';
      this.firstname = '';
      this.phoneNumber = '';
      this.email = '';
      this.line = '';
      this.qq = '';
      this.skype = '';
      this.wechat = '';
      this.zalo = '';
      this.checked = '';
      this.linknumber = '';
    }
  }
})
export default class Login extends Vue {
  mconfig = App.getConfig() || {
    dollarSign: '¥'
  };
  config = App.getConfig()?.ui_registration_contact_info || '';
  proxyConfig = {};
  password = '';
  username = '';
  realname = '';
  lastname = '';
  firstname = '';
  phoneNumber = '';
  phone = '';
  email = '';
  line = '';
  qq = '';
  skype = '';
  wechat = '';
  zalo = '';
  checked = '';
  loading = false;
  showLang = false;
  LangOrderflag = true;
  linknumber = '';
  actions = [{name: 'English'}, {name: '中文简体'}];
  info = {
    phoneNumberMsg: '',
    phoneDisabled: true,
    // phoneWaitTime: 300,
    // phoneErrorWaitTime: 15,
    // verPhoneWaitTime: 0,
    // smsInterval: '',
    // phoneCode: '',
    phone: ''
  };
  submitLoading = true;
  modelRef = reactive<LoginParamsType>({
    username: '',
    password: ''
  });

  handleSubmit() {
    console.log(this.modelRef);
  }
  mounted() {
    const urlParam = this.$router.currentRoute.value.params;
    this.linknumber = urlParam.linkId as string;
    if (typeof this.config && /{/.test(this.config)) {
      this.proxyConfig = JSON.parse(this.config).agent;
    }
  }
}
