<template>
  <div class="body">
    <div class="main">
      <h1 class="title">{{ $t('register__title') }}</h1>
      <el-form :model="modelRef" ref="formRef">
        <el-form-item label="" prop="username">
          <el-input v-model.trim="username" :placeholder="$t('login__usernamePlaceholder')" />
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input
            type="password"
            v-model.trim="password"
            :placeholder="$t('login__passwordPlaceholder')"
          />
        </el-form-item>
        <div v-if="mconfig.dollarSign == '¥'">
          <el-form-item label="" prop="realname">
            <el-input v-model.trim="realname" :placeholder="$t('register__realname')" />
          </el-form-item>
        </div>
        <div :class="[{LangOrderRe: !LangOrderflag}]" v-else>
          <el-form-item label="" prop="lastname">
            <el-input v-model.trim="lastname" :placeholder="$t('register__lastname')" />
          </el-form-item>
          <el-form-item label="" prop="firstname">
            <el-input v-model.trim="firstname" :placeholder="$t('register__firstname')" />
          </el-form-item>
        </div>

        <!-- <el-form-item label="" prop="confirm">
          <el-input
            type="password"
            v-model.trim="realname"
            :placeholder="$t('ui_input_pwd_again')"
          />
        </el-form-item> -->
        <el-form-item label="" prop="email">
          <div class="fakebg">
            <tel-input
              class="tel"
              ::placeholder="$t('test')"
              :value="info.phone"
              ref="tel"
              :preferredCountries="['VN', 'TH']"
              @validate="clearPhoneError"
              style="flex: 1"
            >
            </tel-input>
          </div>
        </el-form-item>
        <el-form-item label="" prop="email" v-if="proxyConfig.email == 4">
          <el-input
            :placeholder="$t('register__email')"
            autocomplete="email"
            v-model.trim="email"
          />
        </el-form-item>
        <el-form-item label="" prop="qq" v-if="proxyConfig.qq == 4">
          <el-input :placeholder="$t('register__qq')" autocomplete="qq" v-model.trim="qq" />
        </el-form-item>
        <el-form-item label="" prop="skype" v-if="proxyConfig.skype == 4">
          <el-input
            :placeholder="$t('register__skype')"
            autocomplete="skype"
            v-model.trim="skype"
          />
        </el-form-item>
        <el-form-item label="" prop="wechat" v-if="proxyConfig.wechat == 4">
          <el-input
            :placeholder="$t('register__wechat')"
            autocomplete="wechat"
            v-model.trim="wechat"
          />
        </el-form-item>
        <el-form-item label="" prop="line" v-if="proxyConfig.line == 4">
          <el-input :placeholder="$t('register__line')" autocomplete="line" v-model.trim="line" />
        </el-form-item>
        <el-form-item label="" prop="zalo" v-if="proxyConfig.zalo == 4">
          <el-input :placeholder="$t('register__zalo')" autocomplete="zalo" v-model.trim="zalo" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" class="submit" @click="submit()">
            <!-- :loading="submitLoading" -->
            {{ $t('login__registerNew') }}
          </el-button>
          <div class="regfooter">
            <Languague />
            <div class="link" @click="$router.push('/login')">
              {{ $t('ui_hint_acc_login') }}
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts">
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';
import Languague from '../../languague/index.vue';
import TelInput from '@/common/web/telInput/index.vue';
import {Server, App} from '@/services';
interface LoginParamsType {
  username: '';
  password: '';
}
@Options({
  components: {
    Languague,
    TelInput
  },
  props: {
    onToggle: Function,
    linknumber: String
  },
  methods: {
    async submit() {
      const data = await Server.Web.Common.register({
        user_account: this.username,
        password: this.password,
        real_name: this.lastname + this.firstname || this.realname,
        phone_number: this.$refs.tel.info.phoneObject.number.e164,
        email: this.email,
        wechat: this.wechat,
        qq: this.qq,
        skype: this.skype,
        line: this.line,
        zalo: this.zalo,
        link_token: this.linknumber
      });
      // console.log('phone_number: ', this.$refs.tel.info.phoneObject.number.e164);
      // this.loading = false;
      if (data instanceof Error) {
        this.$message(data.message);
        return false;
      }
      this.password = '';
      this.username = '';
      this.realname = '';
      this.lastname = '';
      this.firstname = '';
      this.phoneNumber = '';
      this.email = '';
      this.line = '';
      this.qq = '';
      this.skype = '';
      this.wechat = '';
      this.zalo = '';
      this.checked = '';
      this.linknumber = '';
    }
  }
})
export default class Login extends Vue {
  mconfig = App.getConfig() || {
    dollarSign: '¥'
  };
  config = App.getConfig()?.ui_registration_contact_info || '';
  proxyConfig = {};
  password = '';
  username = '';
  realname = '';
  lastname = '';
  firstname = '';
  phoneNumber = '';
  phone = '';
  email = '';
  line = '';
  qq = '';
  skype = '';
  wechat = '';
  zalo = '';
  checked = '';
  loading = false;
  showLang = false;
  LangOrderflag = true;
  linknumber = '';
  actions = [{name: 'English'}, {name: '中文简体'}];
  info = {
    phoneNumberMsg: '',
    phoneDisabled: true,
    // phoneWaitTime: 300,
    // phoneErrorWaitTime: 15,
    // verPhoneWaitTime: 0,
    // smsInterval: '',
    // phoneCode: '',
    phone: ''
  };
  submitLoading = true;
  modelRef = reactive<LoginParamsType>({
    username: '',
    password: ''
  });

  handleSubmit() {
    console.log(this.modelRef);
  }
  mounted() {
    const urlParam = this.$router.currentRoute.value.params;
    this.linknumber = urlParam.linkId as string;
    if (typeof this.config && /{/.test(this.config)) {
      this.proxyConfig = JSON.parse(this.config).agent;
    }
  }
}
</script>
<style lang="scss" scoped>
.body {
  background: url('../../../assets/web/bglogin.jpg');
  width: 100vw;
  height: 100vh;
}
.main {
  min-width: 368px;
  padding: 36px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .title {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
    color: #ffffff;
    /* background-image:-webkit-linear-gradient(right,#FFFFFF,#0f47e6, #FFFFFF);
        -webkit-background-clip: text;
        -webkit-text-fill-color:transparent; */
  }
  .submit {
    width: 100%;
  }

  .regfooter ::v-deep(.name),
  .link {
    color: #fff;
  }
  .regfooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
  }
}
::v-deep(.el-dropdown-menu__item:not(.is-disabled):hover) {
  background-color: unset;
  color: #66b1ff;
}
::v-deep(.el-button--primary) {
  margin: 0;
}
</style>
